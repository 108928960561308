<template>
  <suc-calendar-component
    :cellData="cellData"
    @[getCalendarDataEvent]="init"
    :isLoading="isLoading"
    :type="type"
  >
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
      <slot :name="slot" v-bind="scope" />
    </template>

    <template #cell="props">
      <planning-calendar-item-component :cell="props.cell">
        <template #body="props"> {{ props.cell.getValue }} </template>
      </planning-calendar-item-component>
    </template>
  </suc-calendar-component>
</template>

<script>
import SucCalendarComponent, { GET_CALENDAR_DATA, CellData } from "@/components/calendar";

import PlanningCalendarItemComponent from "./PlanningCalendarItemComponent.vue";
import { DATE_PERIOD_MONTH, DATE_PERIOD_WEEK } from "@/components/form";

import api from "@/api/plan";

export default {
  components: {
    SucCalendarComponent,
    PlanningCalendarItemComponent,
  },
  props: {
    type: {
      type: String,
      validator: function (val) {
        const types = [DATE_PERIOD_WEEK, DATE_PERIOD_MONTH];
        if (!types.includes(val)) {
          console.error("type shoud be one of", types);
          return false;
        }
        return true;
      },
      default: DATE_PERIOD_MONTH,
    },
  },
  data() {
    return {
      cellData: [],
      rawData: [],
      dateStart: null,
      dateEnd: null,
      isLoading: false,
    };
  },
  computed: {
    getCalendarDataEvent() {
      return GET_CALENDAR_DATA;
    },
  },
  methods: {
    async init({ start, end }) {
      this.isLoading = true;
      this.dateStart = start;
      this.dateEnd = end;
      await this.loadData(start, end);
      this.prepare();
      this.isLoading = false;
    },
    async loadData(start, end) {
      const { data } = await api.getPlanEmployeeDetail({ from: start, to: end });
      this.rawData = data;
    },
    prepare() {
      let data = [];
      for (let item of this.rawData) {
        item.date = this.$parseDate(item.date);
        data.push(new CellData({ day: item.date, data: item }));
      }
      this.cellData = data;
    },
  },
};
</script>

<style>
.planning {
  th,
  td {
    padding: 0px;
  }
}
</style>

<template>
  <div role="main" class="container" v-if="activeEmployeePlanning">
    <article>
      <h1 class="mb-4">{{ $t("pages.planning.title") }}</h1>

      <div class="d-flex table-responsive p-2">
        <hours-planning-month-widget />
        <hours-real-month-widget class="ml-4" />
        <hour-balance-widget class="ml-4" v-if="activeHoursPack" />
      </div>

      <planning-calendar-component class="mt-5"> </planning-calendar-component>
    </article>
  </div>
</template>

<script>
import { PlanningCalendarComponent } from "./components";

import { HoursPlanningMonthWidget, HourBalanceWidget, HoursRealMonthWidget } from "./widgets";
import { mapGetters } from "vuex";

import { WhoAmIMixin } from "@/components/mixins";

export default {
  mixins: [WhoAmIMixin],
  layout: "MainLayout",
  middleware: ["auth", "notification"],
  components: {
    PlanningCalendarComponent,
    HoursPlanningMonthWidget,
    HourBalanceWidget,
    HoursRealMonthWidget,
  },
  computed: {
    ...mapGetters("auth", ["activeHoursPack", "activeEmployeePlanning"]),
  },
};
</script>

<style></style>
